import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { FlowData } from './nodes';
import { Parameter } from './types';
import { notebookUser } from '../../../../../redux/workbench/selectors/notebookUser.selector';
import { RootState, useAppDispatch } from '../../../../../store/store';
import {
  parameterAdded,
  parameterRemoved,
  selectSelectedFlowPath,
  subflowAdded,
  subflowDeleted,
  subflowSelected,
} from '../../../../../store/workbench/flowDesigner.slice';

export function useWebSocketConnection(sessionId: string, kernelId: string) {
  const socketRef = useRef<WebSocket | null>(null);
  const jupyterUser = useSelector(notebookUser);

  const [log, setLog] = useState<string>('');

  useEffect(() => {
    if (!sessionId && !kernelId) return;

    const socketUrl = `${
      location.protocol.includes('https') ? 'wss://' : 'ws://'
    }${
      location.hostname + (location.port ? ':' + location.port : '')
    }/jupyter/user/${jupyterUser}/api/kernels/${kernelId}/channels?session_id=${sessionId}`;

    const socket = new WebSocket(socketUrl);

    // Handle socket messages
    socket.onmessage = (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      const messageType = message.header.msg_type;

      switch (messageType) {
        case 'status': {
          // This determines the status of the kernel ('busy' or 'idle') -> This is shown in the icon next to the Kernel Info
          // const sessionIdParentMsg = message.parent_header.session;
          const executionState = message.content.execution_state;

          if (executionState === 'restarting') {
            // This means the kernel was restarted due to a failure, for example since the memory limit was reached
            // and the Kernel process was killed
            alert('Kernel had to be restarted');
            // notification restart kernel
          } else {
            // notification executionState
          }
          break;
        }
        case 'execute_input': {
          // ???
          break;
        }
        case 'execute_reply': {
          if (message.content.status === 'ok') {
            console.log(message.content);
            // append log
          }
          if (message.content.status === 'error') {
            setLog((log) => `${log}\nERROR`);
            // display error
          }
          break;
        }
        case 'execute_result': // Fall-through intended
        case 'stream': // Fall-through intended
        case 'display_data': // Fall-through intended
        case 'error': {
          // append log
          if (message.content.name === 'stdout') {
            setLog((log) => `${log}\n${message.content.text}`);
          } else {
            setLog(
              (log) =>
                `${log}\nReceived message of type: ${messageType} - ${message.content.name}`
            );
          }

          break;
        }
        case 'complete_reply': {
          const completions = message.content;
          break;
        }
        case 'clear_output': {
          setLog('');
          break;
        }
        case 'shutdown_reply': {
          const { status, restart } = message.content;
          if (status === 'ok' && !restart) {
            // The kernel was shutdown (probably via a delete session http call by us)
            // Give it some time to handle other messages. For example for currently executing cells we will receive
            // an execute_reply message with message.content.status === 'error' to show that execution was interrupted
            setTimeout(() => {
              socket.close();
            }, 1000);
            return;
          } else if (status !== 'ok') {
            // Kernel restarts work through the same session and have restart = true, so those are expected
            console.log(
              'Unexpected message content for shutdown_reply websocket message: ',
              message.content
            );
          }
          break;
        }
        case 'debug_reply': {
          switch (message.content.command) {
            case 'inspectVariables':
              setLog(
                `inspectVariables: ${JSON.stringify(message.content.body)}`
              );
              break;
            case 'richInspectVariables':
              setLog(
                `richInspectVariables in1_val_0: ${JSON.stringify(
                  message.content.body
                )}`
              );
              break;
            default:
              console.log(`Unknown debug request: ${message.content.command}`);
          }
          break;
        }
        default: {
          console.log(`unknown message type: ${messageType}`);
        }
      }
    };
    socket.onopen = () => {
      console.log('opened');
    };
    socket.onclose = () => {
      console.log('close');
    };
    socket.onerror = () => {
      console.log('error');
    };

    socketRef.current = socket;
    return () => socket.close();
  }, [jupyterUser, kernelId, sessionId, socketRef]);

  function sendExecuteRequest(source: string) {
    if (!socketRef.current) return;

    const msgId = uuidv4();
    const msg = {
      buffers: [],
      channel: 'shell',
      content: {
        allow_stdin: true,
        code: source,
        silent: false,
        stop_on_error: true,
        store_history: true,
        user_expressions: {},
      },
      header: {
        msg_id: msgId,
        msg_type: 'execute_request',
        session: sessionId,
        username: '',
        version: '5.4',
      },
      metadata: {},
      parent_header: {},
    };

    socketRef.current.send(JSON.stringify(msg));
  }

  function sendInspectVariablesRequest() {
    if (!socketRef.current) return;

    const msgId = uuidv4();
    const msg = {
      buffers: [],
      channel: 'control',
      content: {
        type: 'request',
        command: 'inspectVariables',
        seq: 0, // FIXME: what is this?
      },
      header: {
        msg_id: msgId,
        msg_type: 'debug_request',
        session: sessionId,
        username: '',
        version: '5.4',
      },
      metadata: {},
      parent_header: {},
    };

    socketRef.current.send(JSON.stringify(msg));
  }

  function sendRichInspectVariablesRequest() {
    if (!socketRef.current) return;

    const msgId = uuidv4();
    const msg = {
      buffers: [],
      channel: 'control',
      content: {
        type: 'request',
        command: 'richInspectVariables',
        arguments: {
          variableName: 'in1_val_0',
          frameId: 0,
        },
        seq: 0, // FIXME: this is necessary even though it is not documented
      },
      header: {
        msg_id: msgId,
        msg_type: 'debug_request',
        session: sessionId,
        username: '',
        version: '5.4',
      },
      metadata: {},
      parent_header: {},
    };

    socketRef.current.send(JSON.stringify(msg));
  }

  return {
    log,
    sendExecuteRequest,
    sendInspectVariablesRequest,
    sendRichInspectVariablesRequest,
  };
}

export const useSubflowUtils = (path: string) => {
  const dispatch = useAppDispatch();

  const selectedFlowPath = useSelector((state: RootState) =>
    selectSelectedFlowPath(state, path)
  );

  const addSubflow = useCallback(
    (nodeId: string, subflow: FlowData) => {
      dispatch(
        subflowAdded({
          filePath: path,
          selectedFlowPath,
          subflow,
          parentNodeId: nodeId,
        })
      );
    },
    [dispatch, path, selectedFlowPath]
  );

  const removeSubflow = useCallback(
    (nodeId: string, subflowId: string) => {
      dispatch(
        subflowDeleted({
          filePath: path,
          subflowId,
          selectedFlowPath,
          parentNodeId: nodeId,
        })
      );
    },
    [dispatch, path, selectedFlowPath]
  );

  const openSubflow = useCallback(
    (nodeId: string, subflowId: string) => {
      dispatch(
        subflowSelected({
          filePath: path,
          selectedFlowId: subflowId,
        })
      );
    },
    [dispatch, path]
  );

  const addParameter = useCallback(
    (nodeId: string, gatewayType: 'in' | 'out', parameter: Parameter) => {
      dispatch(
        parameterAdded({
          filePath: path,
          selectedFlowPath,
          nodeId,
          gatewayType,
          parameter,
        })
      );
    },
    [dispatch, path, selectedFlowPath]
  );

  const removeParameter = useCallback(
    (nodeId: string, gatewayType: 'in' | 'out', parameterId: string) => {
      dispatch(
        parameterRemoved({
          filePath: path,
          selectedFlowPath,
          nodeId,
          gatewayType,
          parameterId,
        })
      );
    },
    [dispatch, path, selectedFlowPath]
  );

  return {
    addSubflow,
    removeSubflow,
    openSubflow,
    addParameter,
    removeParameter,
  };
};
